$primary-color: white;
$bg: black;

body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.values {
    display: flex;
    width: 100vw;
    max-width: 500px;
    margin-top: 30px;
    flex-direction: row;
    justify-content: space-around;
}

.values div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.values div input {
    width: 100px;
    text-align: center;
}

#rgb_r {
    color: red;
}

#rgb_g {
    color: green;
}

#rgb_b {
    color: blue;
}
